/* global stripeKey: false, Stripe: false */
(function () {
    'use strict';
    var subscriptionForm = $("#subscription-form");
    if (subscriptionForm.length === 0) {
        return;
    }
    
    console.log("Loading subscr form");
    
    // constants
    var NEW_CARD = "_new";
    var CARD_CONFIG = {
        iconStyle: "solid",
        style: {
            base: {
                iconColor: "#fff",
                // color: "#fff",
                fontWeight: 400,
                fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                fontSize: "15px",
                fontSmoothing: "antialiased",
                color: "#32325D",
                
                "::placeholder": {
                    color: "#CFD7DF"
                },
                ":-webkit-autofill": {
                    color: "#CFD7DF"
                }
            },
            invalid: {
                color: "#E25950"
            }
        }
    };
    var paymentMethod = subscriptionForm.find("input[name=paymentmethod]");
    var newCardForm = $("#payment-method-form");
    var stripe = new Stripe(stripeKey);
    var elements = stripe.elements({
        // let stripe detect the locale
        locale: 'auto'
    });
    /**
     * Card Element
     */
    var card = elements.create("card", CARD_CONFIG);
    card.mount("#card");
    subscriptionForm.find("input[name=paymentmethod]").on("change", function () {
        if (this.value === NEW_CARD) {
            newCardForm.show();
        } else {
            newCardForm.hide();
        }
    });
    var errorField = newCardForm.find('.invalid-feedback');
    
    // Listen for errors from each Element, and show error messages in the UI.
    card.on('change', function (event) {
        if (event.error) {
            errorField.addClass('visible');
            errorField.text(event.error.message);
            $("#card-group").addClass("has-danger");
            $("#card").addClass("is-invalid");
        } else {
            errorField.removeClass('visible');
            $("#card-group").removeClass("is-invalid");
            $("#card").removeClass("form-control-danger");
        }
    });
    
    $.validator.addMethod("conditionalRequired", function (value, element, param) {
        if(selectedPaymentMethod() === NEW_CARD) {
            return true;
        } else {
            return $.validator.methods.required(value, element, param);
        }
    }, jQuery.validator.format("{1} is required."));
    
    // bind the form validation on submit
    subscriptionForm.validate({
        rules: {
            "name": "conditionalRequired",
            "address": "conditionalRequired",
            "city": "conditionalRequired",
            "state": "conditionalRequired",
            "country": "conditionalRequired"
        },
        highlight: function (element) {
            $(element).closest('.form-group').removeClass('success').addClass('error');
        },
        success: function (element) {
            element.addClass('valid').closest('.form-group').removeClass('error').addClass('success');
        },
        submitHandler: validFormSubmit
    });
    
    function validFormSubmit() {
        // Disable all inputs.
        disableInputs();
        
        // check if we have to create a new card or not 
        if (selectedPaymentMethod() === NEW_CARD) {
            console.log("Selected new card, calling Stripe");
            getStripeToken();
        } else {
            console.log("Selected existing card, submitting");
            submitToBackend();
        }
    }
    
    function getStripeToken() {
        console.log("Get stripe token");
        // TODO: show loading screen
        // Gather additional customer data we may have collected in our form.
        var name = $('#name');
        var address1 = $('#address');
        var city = $('#city');
        var state = $('#state');
        var zip = $('#zip');
        var country = $('#country');
        var additionalData = {
            name: name ? name.val() : undefined,
            address_line1: address1 ? address1.val() : undefined,
            address_city: city ? city.val() : undefined,
            address_state: state ? state.val() : undefined,
            address_zip: zip ? zip.val() : undefined,
            address_country: country ? country.val() : undefined
        };
        
        // Use Stripe.js to create a token. We only need to pass in one Element
        // from the Element group in order to create a token. We can also pass
        // in the additional customer data we collected in our form.
        stripe.createToken(card, additionalData).then(function (result) {
            console.log("Stripe response: ", result);
            // TODO: remove loading screen
            if (result.token) {
                // If we received a token, grab the token id and submit the form to the server
                submitToBackend(result.token);
            } else {
                // Otherwise, re-enable inputs and Stripe errors will show
                enableInputs();
            }
        });
    }
    
    /**
     * On a successful Stripe payment method registration, submit the form, excluding Stripe, to the backend.
     * @param token
     */
    function submitToBackend(token) {
        var data = {
            token: token ? token.id : null,
            plan: $("#plan").val(),
            paymentMethod: selectedPaymentMethod()
        };
        console.log(data);
        $.ajax({
            type: "POST",
            url: newCardForm.attr("action"),
            data: data,
            success: function (response) {
                // TODO: on success continue on with a happy modal or something
                if (response.success) {
                    window.location.href = "/account";
                } else {
                    alert(response.errors.join(", "));
                }
            },
            dataType: "json"
        });
    }
    
    function selectedPaymentMethod() {
        if (paymentMethod.prop("type") === "hidden") {
            return NEW_CARD;
        } else {
            return subscriptionForm.find("input[name=paymentmethod]:checked").val();
        }
    }
    
    function enableInputs() {
        Array.prototype.forEach.call(
            $(newCardForm).find("input[type='text'], input[type='email'], input[type='tel'], select"),
            function (input) {
                input.removeAttribute('disabled');
            }
        );
    }
    
    function disableInputs() {
        Array.prototype.forEach.call(
            $(newCardForm).find("input[type='text'], input[type='email'], input[type='tel'], select"),
            function (input) {
                input.setAttribute('disabled', 'true');
            }
        );
    }
})();