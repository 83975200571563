// include our styles
import "../scss/theme.scss"

// make jQuery accessible using $, used by the integration tests
// require("expose-loader?$!jquery");

// bootstrap js
import 'bootstrap';

// base js
import "jquery-validation";
import "./theme.js";