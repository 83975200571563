(function () {
    'use strict';

        var modal = $("#apikeymodal");
        var apiKeyForm = modal.find("form");
        var submitButton = $("#submit-api-form");


        modal.on("show.bs.modal", function(e) {
           if(e.relatedTarget.id === "add-api-key" ) {
               showApiKeyModal(true, e.relatedTarget);
           } else {
               showApiKeyModal(false, e.relatedTarget);
           }
        });

        var delModal = $("#deleteuploadmodal");
        delModal.on("show.bs.modal", function(e) {
           showDeleteUploadModal(e.relatedTarget);
        });
    
        var activeGroup = $("#active-group");
        function showApiKeyModal(isNew, target) {
            if(isNew) {
                modal.find("h5").text("Create new API key");
                modal.find("input[name=key]").val("");
                modal.find("input[name=name]").val("");
                submitButton.text("Create key");
                activeGroup.hide();
                apiKeyForm.attr('action', '/account/api_keys');
            } else {
                modal.find("h5").text("Edit API key");
                var data = $(target).parents("tr").data();
                modal.find("input[name=key]").val(data.key);
                modal.find("input[name=name]").val(data.name);
                modal.find("input[name=active]").prop('checked', data.active);
                submitButton.text("Save changes");
                activeGroup.show();
                apiKeyForm.attr('action', '/account/api_keys/' + data.key);
            }
        }

        function showDeleteUploadModal(target) {
            var data = $(target).parents("tr").data();
            $("#delete-upload-desc").text("Do you really want to delete the CSV upload " + data.name + "? Please make sure you download the results first, as you can't undo this action!");
            $("#upload-id-field").val(data.key);
        }

        apiKeyForm.submit(function (e) {
            e.preventDefault();
            var data = {
                key: apiKeyForm.find("input[name=key]").val(),
                name: apiKeyForm.find("input[name=name]").val(),
                active: apiKeyForm.find("input[name=active]").is(':checked')
            };
            $.ajax({
                type: "POST",
                url: apiKeyForm.attr("action"),
                data: data,
                success: function (response) {
                    if (response.success) {
                        window.location.reload();
                        modal.modal('hide');
                    } else {
                        alert(response.error);
                    }
                },
                dataType: "json"
            });
        });
})();