/* global ga: false */
(function () {
    'use strict';
    const registrationForm = $("#registrationForm");
    if (!registrationForm.length) {
        return;
    }
    
    registrationForm.validate({
        ignore: ".ignore",
        highlight: function (element) {
            $(element).closest('.form-group').removeClass('success').addClass('error');
        },
        success: function (element) {
            element.addClass('valid').closest('.form-group').removeClass('error').addClass('success');
        },
        submitHandler: function(form, event) {
            event.preventDefault();
            const data = {
                name: registrationForm.find("input[name=name]").val(),
                email: registrationForm.find("input[name=email]").val(),
                password: registrationForm.find("input[name=password]").val(),
                tos: registrationForm.find("input[name=tos]").val(),
                recaptcha: reCaptchaToken
            };
            $.ajax({
                type: "POST",
                url: registrationForm.attr("action"),
                data: data,
                success: function (response) {
                    if (response.success) {
                        try {
                            ga('send', 'event', 'Registration', 'finished');
                        } catch(e) {
                        }
                        // TODO: on success continue on with a happy modal or something
                        window.location.href = "/";
                    } else {
                        try {
                            ga('send', 'event', 'Registration', 'error');
                        } catch(e) {
                        }
                        alert(response.errors.join(" "));
                        try {
                          grecaptcha.reset();
                        } catch(e) {
                          console.debug(e);
                        }
                    }
                },
                error: function(response) {
                    try {
                        ga('send', 'event', 'Registration', 'serverError');
                    } catch(e) {
                    }
                    alert(response.errors.join(" "));
                  try {
                    grecaptcha.reset();
                  } catch(e) {
                    console.debug(e);
                  }
                },
                dataType: "json"
            });
        }
    });
})();
