(function () {
    // prevent dropdown link click to hide dropdown
    $('.navbar-nav .dropdown-item').click(function (e) {
        e.stopPropagation();
    });
    
    // toggle for dropdown submenus
    $('.dropdown-submenu .dropdown-toggle').click(function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('show');
        $(this).siblings('.dropdown-menu').toggleClass('show');
    });
})();