import './common';

// signed in pages
import "./dashboard.js";
import "./subscription.js";

// public pages
import "./registration.js";

/* global ga: false */
if (typeof window.onerror === 'object' && window.onerror === null) {  // Checks if this seat is taken/exists
    window.onerror = function(message, file, lineNumber) {
        if (typeof ga === 'object') {  // Detects Universal Analytics
            ga('send','exception', 'JS Error',
                {
                    'exDescription': err.message + ", URL: " + document.location.href + ", file:" + file + '| Line: ' + lineNumber,
                    'exFatal': false
                }
            );
        }
    }
}